<template>
  <div class="what-i-did">
		<loader :is-ready="isReady" :is-disable="isDisable"></loader>
    <h2><span class="txt-highlight">What</span> I did</h2>
    <h3>20<span class="txt-highlight">16</span> - 20<span class="txt-highlight">21</span></h3>
    <ul>
			<li>Frontend team leader for the startup <span class="txt-highlight">Epicentric</span></li>
			<li>Migration of the desktop app Epicentric to a web app. The frontend is using mainly <span class="txt-highlight">Vue.js with Vuex and D3.js</span></li>
			<li>Design functionalities and developing features for Epicentric</li>
			<li><span class="txt-highlight">[Freelance]</span> Collaborating with many studios on variuos projects</li>
			<li><span class="txt-highlight">[Freelance]</span> Developing variuos portals as a freelance (standards and e-commerce)</li>
			<li><span class="txt-highlight">[Freelance]</span> Develop and bug fixing widgets for the private area of the new portal of one of the biggest bank in Italy. The client side was powered with <span class="txt-highlight">AngularJs</span>, sass and a custom framework to draw and animate the graphs</li>
    </ul>
		<h3>20<span class="txt-highlight">15</span></h3>
		<ul>
			<li>Starting to work as a freelance</li>
			<li><span class="txt-highlight">[Freelance]</span> Working on performance optimization, bug fixing and mobile compatibility on a international project for a multi-national tobacco company</li>
    </ul>
		<h3>20<span class="txt-highlight">12</span> - 20<span class="txt-highlight">14</span></h3>
		<ul>
			<li><span class="txt-highlight">Unity3D</span> programmer on a mobile game</li>
			<li>HTML/CSS - javascript Lead for the <span class="txt-highlight">mobile videogame</span> NFL Matchups (iOS and Android)</li>
			<li>Development of templates and functionalities for <span class="txt-highlight">mobile games (iOS and Android)</span></li>
    </ul>
		<h3>20<span class="txt-highlight">10</span> - 20<span class="txt-highlight">12</span></h3>
		<ul>
			<li>Design and development <span class="txt-highlight">web application and mini-games for iPhone and iPad</span></li>
			<li>Design and development websites with <span class="txt-highlight">DotNetNuke</span></li>
			<li>Development of custom DotNetNuke modules</li>
			<li>Development of <span class="txt-highlight">LMS system (SCORM certified) and E-learning contents</span></li>
    </ul>
    <h3>20<span class="txt-highlight">09</span> - 20<span class="txt-highlight">10</span></h3>
		<ul>
			<li>Website development with <span class="txt-highlight">Joomla and Wordpress</span></li>
			<li>Solve urgent customer problems concerning installation, configuration and utilization of various ERP software</li>
    </ul>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue'

export default {
  name: 'What-did',
  components: {
    Loader
  },
  data () {
		return {
			isReady: false,
			isDisable: false
		}
	},
	beforeRouteLeave (to, from, next) {
    let self = this
		this.isDisable = false
		setTimeout(function () {
      self.isReady = false
    }, 50)
    setTimeout(function () {
      next()
    }, 650)
  },
	head: {
		title: {
			inner: 'Lorenzo Cogliati - What I did'
		},
		meta: [
			{ name: 'application-name', content: 'Cogliawho' },
			{ name: 'description', content: 'Hi! My name is Lorenzo and this is my journey as a web developer. I\'m specialized in developing SPA with Vue.js' },
			// Twitter
			{ name: 'twitter:title', content: 'Lorenzo Cogliati - What I do' },
			// with shorthand
			{ n: 'twitter:description', c: 'Hi! My name is Lorenzo and this is my journey as a web developer. I\'m specialized in developing SPA with Vue.js'},
			// Google+ / Schema.org
			{ itemprop: 'name', content: 'Lorenzo Cogliati - What I did' },
			{ itemprop: 'description', content: 'Hi! My name is Lorenzo and this is my journey as a web developer. I\'m specialized in developing SPA with Vue.js' },
			{ p: 'og:image', c: 'https://cogliawho.it' + require('@/assets/coglia_who_logo.png') },
		]
	},
  mounted () {
		let self = this
		setTimeout(function () {
			self.isReady = true
    }, 100)
    setTimeout(function () {
			self.isDisable = true
    }, 750)
  }
}
</script>

<style lang="scss">
.what-i-did{
	height: 100%;
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
	overflow: auto;
	padding-right: 10px;
	h2{
		margin-bottom: 2rem;
	}
	h3{
		margin-bottom: 1rem;		
	}
	p{
		text-align: justify;
		padding-right: 10px;
	}
	ul{
		margin-bottom: 2rem;
		padding-right: 10px;
		text-align: justify;
	}
}
</style>
